<template>
  <div>

    <b-row>
      <b-col md="12">
        <div class="card p-2 bg-blue">
          <div class="d-flex justify-content-between align-items-center">
            <h3 class="text-white">
              {{ $t('key-130') }}
            </h3>
          </div>

          <div class="card p-2 mt-1">
            <div class="anan-order">
              <div class="row">
                <div class="col-md-6">
                  <table>
                    <tbody>
                      <tr class="anan-order-row">
                        <td
                          class=""
                          style="width: 40%;"
                        >
                          <p class="txt-odr mb-0">
                            Ref.
                          </p>
                        </td>
                        <td
                          class="text-left pl-1"
                          style="width: 60%;"
                        >
                          <p class="txt-odr mb-0">
                            txt-#1234
                          </p>
                        </td>
                      </tr>

                      <tr class="anan-order-row">
                        <td
                          class=""
                          style="width: 40%;"
                        >
                          <p class="txt-odr mb-0">
                            {{ $t('customerCode') }} :
                          </p>
                        </td>
                        <td
                          class="text-left pl-1"
                          style="width: 60%;"
                        >
                          <p class="txt-odr mb-0">
                            <b-link :to="{ name: 'admin-customer-detail' }">
                              0001
                            </b-link>
                          </p>
                        </td>
                      </tr>

                      <tr class="anan-order-row">
                        <td style="width: 40%;">
                          <p class="txt-odr mb-0">
                            {{ $t('mnemonic') }} :
                          </p>
                        </td>
                        <td
                          class="text-left pl-1"
                          style="width: 60%;"
                        >
                          <p class="txt-odr mb-0">
                            ----
                          </p>
                        </td>
                      </tr>

                      <tr class="anan-order-row">
                        <td
                          class=""
                          style="width: 40%;"
                        >
                          <p class="txt-odr mb-0">
                            {{ $t('deliveryType') }} :
                          </p>
                        </td>
                        <td
                          class="text-left pl-1"
                          style="width: 60%;"
                        >
                          <div v-if="s">
                            {{ $t('byship') }} <i class="fas fa-ship" />
                          </div>

                          <div>
                            {{ $t('byTruck') }} <i class="fas fa-shipping-fast" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col-md-6">
                  <table>
                    <tbody>
                      <tr class="anan-order-row">
                        <td
                          class="text-right"
                          style="width: 80%;"
                        >
                          <p class="txt-odr mb-0">
                            {{ $t('status') }}
                          </p>
                        </td>
                        <td
                          class="text-left pl-1"
                          style="width: 20%;"
                        >
                          <p class="txt-odr mb-0">
                            <b-badge
                              variant="warning"
                              pill
                            >
                              {{ $t('pendingReview') }}
                            </b-badge>
                          </p>
                        </td>
                      </tr>

                      <tr class="anan-order-row">
                        <td
                          class="text-right"
                          style="width: 80%;"
                        >
                          <p class="txt-odr mb-0">
                            {{ $t('transactionDate') }}
                          </p>
                        </td>
                        <td
                          class="text-left pl-1"
                          style="width: 20%;"
                        >
                          <p class="txt-odr mb-0">
                            00/00/00
                          </p>
                        </td>
                      </tr>

                      <tr class="anan-order-row">
                        <td
                          class="text-right"
                          style="width: 80%;"
                        >
                          <p class="txt-odr mb-0">
                            {{ $t('exRate') }}
                          </p>
                        </td>
                        <td
                          class="text-left pl-1"
                          style="width: 20%;"
                        >
                          <p class="txt-odr mb-0">
                            ¥1 = 5.10 ฿
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col-md-6 my-2 px-2">
                  <h5>{{ $t('orderNoteUser') }}</h5>

                  <b-form-textarea
                    v-model="keyword"
                    row="2"
                    disabled
                  />
                </div>

                <div class="col-md-6 my-2 px-2">
                  <h5>{{ $t('orderNoteAdmin') }}</h5>

                  <b-form-textarea
                    v-model="keyword"
                    row="2"
                    disabled
                  />
                </div>

                <div class="col-md-12">
                  <p>
                    {{ $t('inspector') }} : <b-link :to="{ name: 'admin-customer-detail' }">
                      0001
                    </b-link>
                  </p>

                  <p>
                    วันที่แก้ไขล่าสุด : 00/00/00 00:00:00
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </b-col>

      <!-- <b-col md="12">
        <div class="card">
          <div class="header-cardx p-2">
            <h3 class="mb-0">
              รายการจำนวนเงินที่เกี่ยวข้อง
            </h3>
          </div>

          <div class="pl-2 pr-2">
            <b-table
              responsive="sm"
              :fields="fields"
              :items="itemser"
              show-empty
            >
              <template #empty="scope">
                <b-table-empty-slot>
                  <div class="text-center my-2">
                    <img
                      src="@/assets/images/anan-img/svg/doc_empty.svg"
                      alt="empty"
                      height="60px"
                    >
                    <p class="text-muted">
                      No Data
                    </p>
                  </div>
                </b-table-empty-slot>
              </template>
            </b-table>
          </div>
        </div>
      </b-col> -->

      <b-col md="12">
        <div class="card p-2">

          <h3 class="mb-0 font-weight-bolder">
            {{ $t('listProducts') }}
          </h3>

          <div class="mt-1">
            <table style="width: 100%;">
              <tbody>
                <tr class="anan-order-row">
                  <td style="width: 25%;">
                    <p class="txt-odrx mb-0">
                      {{ $t('key-131') }}
                    </p>
                  </td>

                  <td
                    class=""
                    style="width: 25%;"
                  >
                    <p class="txt-odrx mb-0 d-flex align-items-center">
                      <span class="mr5">{{ $t('type') }}</span>
                      <b-form-select
                        v-model="type"
                        :options="typesOp"
                        size="sm"
                        class="w-25"
                        disabled
                      />
                    </p>
                  </td>

                  <td
                    class=""
                    style="width: 10%;"
                  >
                    <b-form-checkbox
                      v-model="checked"
                      name="check-button"
                      value="1"
                      disabled
                    >
                      <p class="txt-odrx mb-0">
                        {{ $t('woodPacking') }}
                      </p>
                    </b-form-checkbox>
                  </td>

                  <td
                    class=""
                    style="width: 10%;"
                  >
                    <b-form-checkbox
                      v-model="checked"
                      name="check-button"
                      value="1"
                      disabled
                    >
                      <p class="txt-odrx mb-0">
                        {{ $t('checkOrder') }}
                      </p>
                    </b-form-checkbox>
                  </td>

                  <td
                    class=""
                    style="width: 10%;"
                  >
                    <b-form-checkbox
                      v-model="checked"
                      name="check-button"
                      value="1"
                      disabled
                    >
                      <p class="txt-odrx mb-0">
                        {{ $t('repackage') }}
                      </p>
                    </b-form-checkbox>
                  </td>

                  <td
                    class=""
                    style="width: 10%;"
                  >
                    <b-form-checkbox
                      v-model="checked"
                      name="check-button"
                      value="1"
                      disabled
                    >
                      <p class="txt-odrx mb-0">
                        {{ $t('cashOndelivery') }}
                      </p>
                    </b-form-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <table style="width: 100%;">

              <!-- colgroup -->
              <colgroup>
                <col style="width: 60%;">
                <col style="width: 10%;">
                <col style="width: 10%;">
                <col style="width: 10%;">
                <col style="width: 10%;">
              </colgroup>

              <thead>
                <tr style="background-color: rgb(215, 233, 250); border-radius: 10px !important;">
                  <th class="p-1">
                    <div>
                      <span> 1. 深圳市龙岗区维妙奇玩具商行</span>
                    </div>
                  </th>

                  <th class="text-center">
                    {{ $t('quantity') }}
                  </th>

                  <th class="text-center">
                    {{ $t('PerItem') }} [¥]
                  </th>

                  <th class="text-center">
                    {{ $t('total') }} [¥]
                  </th>

                  <th class="text-center">
                    {{ $t('total') }} [฿]
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in 10"
                  :key="index"
                  class="anan-order-row"
                  :style="`background-color: ${color};`"
                >
                  <td class="d-flex align-items-center p-1">
                    <span>1.{{ index + 1 }}</span>

                    <img
                      src="https://down-th.img.susercontent.com/file/th-11134207-23020-80u356d0s3nvce"
                      alt="asd"
                      height="50px"
                      class="ml-2 rounded mr5"
                    >

                    <div>
                      <h5 class="mb-0 JB57cn2">
                        潮流休闲服小新公仔卡通动漫大号pvc玩具蛋
                      </h5>
                      <p class="mb-0">
                        {{ $t('color') }} : {{ $t('key-132') }}
                      </p>
                      <p class="mb-0">
                        {{ $t('size') }} : {{ $t('size') }} 1
                      </p>
                    </div>
                  </td>

                  <td
                    class="text-center"
                    style="vertical-align: middle;"
                  >
                    <p class="mb-0">
                      2
                    </p>
                  </td>

                  <td
                    class="text-center"
                    style="vertical-align: middle;"
                  >
                    <p class="mb-0">
                      16.3
                    </p>
                  </td>

                  <td
                    class="text-center"
                    style="vertical-align: middle;"
                  >
                    <p class="mb-0">
                      33.00
                    </p>
                  </td>

                  <td
                    class="text-center"
                    style="vertical-align: middle;"
                  >
                    <p class="mb-0">
                      181.50
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card-info mt-1">
            <div class="row">
              <div class="col-md-8">
                <span
                  v-for="item in 12"
                  :key="item"
                  class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                >
                  <img
                    src="https://down-th.img.susercontent.com/file/th-11134207-23020-80u356d0s3nvce"
                    alt="asd"
                    class="rounded mr5"
                  >
                </span>
              </div>

              <div class="col-md-4">
                <div>
                  <table style="width: 100%;">
                    <!-- colgroup -->
                    <colgroup>
                      <col style="width: 50%;">
                      <col style="width: 25%;">
                      <col style="width: 25%;">
                    </colgroup>

                    <tbody>
                      <tr>
                        <td>
                          <p class="mb-0 font-weight-bolder">
                            {{ $t('totalProductprice') }}
                          </p>
                        </td>
                        <td>
                          <p class="mb-0 font-weight-bolder text-success">
                            ¥ 190.00
                          </p>
                        </td>
                        <td class="text-right">
                          <p class="mb-0 font-weight-bolder text-primary">
                            ฿ 1,000.00
                          </p>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <p class="mb-0">
                            {{ $t('ProductInspectionfee') }} (฿)
                          </p>
                        </td>
                        <td>
                          <p class="mb-0 font-weight-bolder text-success" />
                        </td>
                        <td class="text-right">
                          <p class="mb-0 font-weight-bolder text-primary">
                            ฿ 0.00
                          </p>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <p class="mb-0">
                            {{ $t('woodenCrates') }} (฿)
                          </p>
                        </td>
                        <td>
                          <p class="mb-0 font-weight-bolder text-success" />
                        </td>
                        <td class="text-right">
                          <p class="mb-0 font-weight-bolder text-primary">
                            ฿ 0.00
                          </p>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <p class="mb-0">
                            {{ $t('newWrappingcost') }} (฿)
                          </p>
                        </td>
                        <td>
                          <p class="mb-0 font-weight-bolder text-success" />
                        </td>
                        <td class="text-right">
                          <p class="mb-0 font-weight-bolder text-primary">
                            ฿ 0.00
                          </p>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <p class="mb-0">
                            {{ $t('cashOndelivery') }} (฿)
                          </p>
                        </td>
                        <td>
                          <p class="mb-0 font-weight-bolder text-success" />
                        </td>
                        <td class="text-right">
                          <p class="mb-0 font-weight-bolder text-primary">
                            ฿ 0.00
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-12">
                <table style="width: 100%;">

                  <thead>
                    <tr>
                      <th class="p-1">
                        {{ $t('parcelCode') }}
                      </th>

                      <th class="text-center">
                        {{ $t('productLot') }}
                      </th>

                      <th class="text-center">
                        {{ $t('arrivedChinaWarehouse') }}
                      </th>

                      <th class="text-center">
                        {{ $t('leftChinaWarehouse') }}
                      </th>

                      <th class="text-center">
                        {{ $t('arrivedThaiWarehouse') }}
                      </th>

                      <th class="text-center">
                        {{ $t('x-x') }}
                      </th>

                      <th class="text-center">
                        {{ $t('key-133') }}
                      </th>

                      <th class="text-center">
                        {{ $t('amount') }}
                      </th>

                      <th class="text-center">
                        {{ $t('calculateByKilo') }}(฿)
                      </th>

                      <th class="text-center">
                        {{ $t('queue') }}(฿)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        1231231
                      </td>
                      <td class="text-center">
                        1
                      </td>
                      <td class="text-center">
                        1
                      </td>
                      <td class="text-center">
                        1
                      </td>
                      <td class="text-center">
                        1
                      </td>
                      <td class="text-center">
                        1
                      </td>
                      <td class="text-center">
                        1
                      </td>
                      <td class="text-center">
                        1
                      </td>
                      <td class="text-center">
                        1
                      </td>
                      <td class="text-center">
                        1
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="mt-2 d-flex align-items-center">
                  <p class="mb-0 font-weight-bolder text-danger">
                    {{ $t('remarks') }} : {{ $t('none') }}
                  </p>
                </div>
              </div>
            </div>

          </div>

          <div class="row mt-3">
            <div class="col-md-3">
              <div class="card-total">
                <p class="font-weight-bolder">
                  {{ $t('itemCount') }}
                </p>
                <h3 class="font-weight-bolder">
                  6
                </h3>
              </div>
            </div>

            <div class="col-md-3">
              <div class="card-total">
                <p class="font-weight-bolder">
                  {{ $t('numberOfproducts') }}
                </p>
                <h3 class="font-weight-bolder">
                  6
                </h3>
              </div>
            </div>

            <div class="col-md-3">
              <div class="card-total">
                <p class="font-weight-bolder">
                  {{ $t('exRate') }}
                </p>
                <h3 class="font-weight-bolder">
                  6.00
                </h3>
              </div>
            </div>

            <div class="col-md-3">
              <div class="card-total">
                <p class="font-weight-bolder">
                  {{ $t('key-134') }} [¥]
                </p>
                <h3 class="font-weight-bolder">
                  ¥ 6.00
                </h3>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-6" />
            <div class="col-md-6">
              <div class="card-info">
                <h4 class="font-weight-bolder">
                  {{ $t('summaryListofexpenses') }}
                </h4>
                <hr>
                <div class="d-flex justify-content-between">
                  <p>{{ $t('key-135') }} ({{ $t('key-136') }})</p>

                  <p class="font-weight-bolder">
                    ฿ 1,000.00
                  </p>
                </div>
                <p>{{ $t('key-137') }}</p>

                <div>
                  <h4 class="font-weight-bolder">
                    {{ $t('specialServicecosts') }}
                  </h4>

                  <div class="d-flex justify-content-between pl-2 pr-2">
                    <p class="mb-0">
                      {{ $t('ProductInspectionfee') }} [฿]
                    </p>

                    <p class="font-weight-bolder">
                      0.00
                    </p>
                  </div>

                  <div class="d-flex justify-content-between pl-2 pr-2">
                    <p class="mb-0">
                      {{ $t('woodenCrates') }} [฿]
                    </p>

                    <p class="font-weight-bolder">
                      0.00
                    </p>
                  </div>

                  <div class="d-flex justify-content-between pl-2 pr-2">
                    <p class="mb-0">
                      {{ $t('key-138') }} [฿]
                    </p>

                    <p class="font-weight-bolder">
                      0.00
                    </p>
                  </div>

                  <div class="d-flex justify-content-between pl-2 pr-2">
                    <p class="mb-0">
                      {{ $t('key-139') }} [฿]
                    </p>

                    <p class="font-weight-bolder">
                      0.00
                    </p>
                  </div>
                </div>

                <p class="mb-0">
                  {{ $t('useDiscountCoupon') }} : {{ $t('none') }}
                </p>

                <hr>

                <div class="d-flex justify-content-between">
                  <h3 class="font-weight-bolder">
                    {{ $t('key-66') }}
                  </h3>

                  <h3 class="font-weight-bolder text-primary">
                    1,000.00
                  </h3>
                </div>
              </div>

            </div>
          </div>

        </div>
      </b-col>
    </b-row>

    <b-modal
      ref="my-modal"
      :title="$t('orderRate')"
      size="sm"
    >
      <b-form-group>
        <label for="rate">{{ $t('exRate') }}</label>
        <b-form-input
          id="rate"
          v-model="rate"
          type="number"
          :placeholder="$t('exRate')"
        />
      </b-form-group>
    </b-modal>

    <b-modal
      ref="my-remark"
      :title="$t('key-140')"
      size="sm"
    >
      <b-form-group>
        <label for="remark">{{ $t('remarks') }}</label>
        <b-form-textarea
          id="remark"
          v-model="remark"
          type="text"
        />
      </b-form-group>
    </b-modal>

    <b-modal
      ref="my-color"
      :title="$t('key-141')"
      size="sm"
      hide-footer
    >
      <div class="d-flex justify-content-center align-items-center">
        <div
          style="width: 50px; height: 50px; background-color: rgb(255, 255, 255); border: 1px solid#ccc;"
          class="mr-1"
          @click="selectedColor('rgb(255, 255, 255)')"
        />

        <div
          style="width: 50px; height: 50px; background-color: rgb(241, 241, 241);"
          class="mr-1"
          @click="selectedColor('rgb(241, 241, 241)')"
        />

        <div
          style="width: 50px; height: 50px; background-color: rgb(209, 231, 251);"
          class="mr-1"
          @click="selectedColor('rgb(209, 231, 251)')"
        />

        <div
          style="width: 50px; height: 50px; background-color: rgb(230, 253, 205);"
          class="mr-1"
          @click="selectedColor('rgb(230, 253, 205)')"
        />

        <div
          style="width: 50px; height: 50px; background-color: rgb(255, 231, 231);"
          class="mr-1"
          @click="selectedColor('rgb(255, 231, 231)')"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BModal,
  BLink,
  BBadge,
  BFormSelect,
  BFormCheckbox,
  VBTooltip,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import 'vue-context/dist/css/vue-context.css'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BModal,
    BFormSelect,
    BLink,
    BFormCheckbox,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      color: '',
      name: '',
      category: '',
      code: '',
      details: '',
      type: null,
      typesOp: [
        { value: null, text: 'ทั้งหมด' },
        { value: '1', text: 'A' },
        { value: '2', text: 'B' },
        { value: '3', text: 'C' },
      ],
      selectedFiles: [],
      uploadedImages: [],

      items: [
        { create_date: '00/00/00 00:00:00', detail: 'ฟหกฟหกฟหก', amount: '1' },
        { create_date: '00/00/00 00:00:00', detail: 'ฟหกฟหกฟหก', amount: '1' },
        { create_date: '00/00/00 00:00:00', detail: 'ฟหกฟหกฟหก', amount: '1' },
      ],
    }
  },
  computed: {
    fields() {
      return [
        { key: 'create_date', label: 'วันที่ทำรายการ', thStyle: { width: '15%' } },
        { key: 'detail', label: 'รายละเอียด', thStyle: { width: '70%' } },
        { key: 'amount', label: 'จำนวน', thStyle: { width: '15%' } },
      ]
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  mounted() {
    this.GetOrderDetail()
  },
  methods: {
    GetOrderDetail() {
      const params = {
        ID: this.$route.params.id,
      }
      this.$http
        .post('/order/OrderById', params)
        .then(response => {
          // console.log(response.data)
          this.details = response.data
          // eslint-disable-next-line
          for (const index in this.details.shops) {
            this.details.shops[index].postnumber = []
            this.CalPostNumber(this.details.shops[index], index)
          }
        })
        .catch(error => console.log(error))
    },
    CalPostNumber(item, ShopIndex) {
      const FindShop = this.PostNumberArr.find(ele => ele.ShopIndex === ShopIndex)
      if (FindShop) {
        this.details.shops[ShopIndex].postnumber = FindShop.item
      } else {
        this.GetPostNumber(item, ShopIndex)
      }
    },
    async GetPostNumber(item, ShopIndex) {
      try {
        const params = {
          // eslint-disable-next-line no-underscore-dangle
          shop_id: item._id,
        }
        const { data: res } = await this.$http.get('/postnumber/byshop', { params })
        if (res.success) {
          this.details.shops[ShopIndex].postnumber = res.data
          this.details = { ...this.details }
          const obj = { ShopIndex, item: res.data }
          this.PostNumberArr.push(obj)
        }
      } catch (error) {
        console.log(error)
      }
      return []
    },
    selectedColor(color) {
      // change color by index
      this.color = color
      this.$refs['my-color'].hide()
    },
    addAddr() {
      this.$refs.addRules.validate().then(success => {
        if (success) {
          this.$bvModal.hide('add-addr')
        }
      })
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    showRemark() {
      this.$refs['my-remark'].show()
    },
    showColor(id) {
      this.id = id
      this.$refs['my-color'].show()
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-context.scss';
</style>
